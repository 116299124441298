<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h6 my-4 pointer" @click="backToProject">
          <v-icon class="pr-1">mdi-arrow-left</v-icon>
          <span class="primary--text">{{ $vuetify.lang.t('$vuetify.ilm.labels.backToSearchPage') }}</span>
        </div>
        <article-card v-if="article.summary && article.spans" :get-full-article="getArticle" :article="article" mode="article" />
        <div class="text-center mt-10 mb-10" v-else>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectService from "@/modules/projects/services/ProjectService.js"

import ArticleCard from '@/modules/intelligentLiteratureMonitoring/components/ArticleCard.vue'

export default {

  name: 'Article',
  components: { ArticleCard },
  data () {
    return {
      article: {
        article_links: [],
        authors: []
      }
    }
  },
  methods: {
    getArticle() {
      return ProjectService.getArticle(this.$route.params.projectId, this.$route.params.articleId)
    },
    backToProject() {
      this.$router.push(`/ilm/${ this.$route.params.projectId }`)
    }
  },
  created() {
    this.getArticle().then(response => {
      this.article = response.data
    })
  }
}
</script>

<style lang="css" scoped>
</style>